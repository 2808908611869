import React from 'react';
import PropTypes from 'prop-types';
import styles from './tab-plazma.module.scss';

function TabPlazma({ tab }) {

	const { name } = tab;

	return (
		<section className={styles.wrapper}>
			{name === 'stal' &&
				<table className={styles.table}>
					<tbody>
						<tr className={styles.table__line}>
							<td className={styles.table__item}>До 1 мм</td>
							<td className={styles.table__item}>от 20 руб.</td>
						</tr>
						<tr className={styles.table__line}>
							<td className={styles.table__item}>До 2 мм</td>
							<td className={styles.table__item}>от 25 руб.</td>
						</tr>
						<tr className={styles.table__line}>
							<td className={styles.table__item}>До 3 мм</td>
							<td className={styles.table__item}>от 30 руб.</td>
						</tr>
						<tr className={styles.table__line}>
							<td className={styles.table__item}>До 4 мм</td>
							<td className={styles.table__item}>от 35 руб.</td>
						</tr>
						<tr className={styles.table__line}>
							<td className={styles.table__item}>До 5 мм</td>
							<td className={styles.table__item}>от 40 руб.</td>
						</tr>
						<tr className={styles.table__line}>
							<td className={styles.table__item}>До 6 мм</td>
							<td className={styles.table__item}>от 45 руб.</td>
						</tr>
						<tr className={styles.table__line}>
							<td className={styles.table__item}>До 8 мм</td>
							<td className={styles.table__item}>от 50 руб.</td>
						</tr>
						<tr className={styles.table__line}>
							<td className={styles.table__item}>До 10 мм</td>
							<td className={styles.table__item}>от 60 руб.</td>
						</tr>
						<tr className={styles.table__line}>
							<td className={styles.table__item}>До 12 мм</td>
							<td className={styles.table__item}>от 70 руб.</td>
						</tr>
						<tr className={styles.table__line}>
							<td className={styles.table__item}>До 14 мм</td>
							<td className={styles.table__item}>от 80 руб.</td>
						</tr>
						<tr className={styles.table__line}>
							<td className={styles.table__item}>До 16 мм</td>
							<td className={styles.table__item}>от 90 руб.</td>
						</tr>
						<tr className={styles.table__line}>
							<td className={styles.table__item}>До 18 мм</td>
							<td className={styles.table__item}>от 100 руб.</td>
						</tr>
						<tr className={styles.table__line}>
							<td className={styles.table__item}>До 20 мм</td>
							<td className={styles.table__item}>от 110 руб.</td>
						</tr>
						<tr className={styles.table__line}>
							<td className={styles.table__item}>До 22 мм</td>
							<td className={styles.table__item}>от 120 руб.</td>
						</tr>
						<tr className={styles.table__line}>
							<td className={styles.table__item}>До 25 мм</td>
							<td className={styles.table__item}>от 200 руб.</td>
						</tr>
						<tr className={styles.table__line}>
							<td className={styles.table__item}>До 30 мм</td>
							<td className={styles.table__item}>от 250 руб.</td>
						</tr>
						<tr className={styles.table__line}>
							<td className={styles.table__item}>До 32 мм</td>
							<td className={styles.table__item}>от 300 руб.</td>
						</tr>
						<tr className={styles.table__line}>
							<td className={styles.table__item}>До 38 мм</td>
							<td className={styles.table__item}>от 400 руб.</td>
						</tr>
						<tr className={styles.table__line}>
							<td className={styles.table__item}>До 40 мм</td>
							<td className={styles.table__item}>от 450 руб.</td>
						</tr>
						<tr className={styles.table__line}>
							<td className={styles.table__item}>До 50 мм</td>
							<td className={styles.table__item}>от 550 руб.</td>
						</tr>
						<tr className={styles.table__line}>
							<td className={styles.table__item}>До 58 мм</td>
							<td className={styles.table__item}>от 650 руб.</td>
						</tr>
						<tr className={styles.table__line}>
							<td className={styles.table__item}>До 60 мм</td>
							<td className={styles.table__item}>от 700 руб.</td>
						</tr>
					</tbody>
				</table>
			}
			{name === 'nerzh' &&
				<table className={styles.table}>
					<tbody>
						<tr className={styles.table__line}>
							<td className={styles.table__item}>До 1 мм</td>
							<td className={styles.table__item}>от 60 руб.</td>
						</tr>
						<tr className={styles.table__line}>
							<td className={styles.table__item}>До 2 мм</td>
							<td className={styles.table__item}>от 70 руб.</td>
						</tr>
						<tr className={styles.table__line}>
							<td className={styles.table__item}>До 3 мм</td>
							<td className={styles.table__item}>от 75 руб.</td>
						</tr>
						<tr className={styles.table__line}>
							<td className={styles.table__item}>До 4 мм</td>
							<td className={styles.table__item}>от 80 руб.</td>
						</tr>
						<tr className={styles.table__line}>
							<td className={styles.table__item}>До 5 мм</td>
							<td className={styles.table__item}>от 95 руб.</td>
						</tr>
						<tr className={styles.table__line}>
							<td className={styles.table__item}>До 6 мм</td>
							<td className={styles.table__item}>от 100 руб.</td>
						</tr>
						<tr className={styles.table__line}>
							<td className={styles.table__item}>До 8 мм</td>
							<td className={styles.table__item}>от 110 руб.</td>
						</tr>
						<tr className={styles.table__line}>
							<td className={styles.table__item}>До 10 мм</td>
							<td className={styles.table__item}>от 130 руб.</td>
						</tr>
						<tr className={styles.table__line}>
							<td className={styles.table__item}>До 12 мм</td>
							<td className={styles.table__item}>от 160 руб.</td>
						</tr>
						<tr className={styles.table__line}>
							<td className={styles.table__item}>До 14 мм</td>
							<td className={styles.table__item}>от 230 руб.</td>
						</tr>
						<tr className={styles.table__line}>
							<td className={styles.table__item}>До 16 мм</td>
							<td className={styles.table__item}>от 240 руб.</td>
						</tr>
						<tr className={styles.table__line}>
							<td className={styles.table__item}>До 18 мм</td>
							<td className={styles.table__item}>от 250 руб.</td>
						</tr>
						<tr className={styles.table__line}>
							<td className={styles.table__item}>До 20 мм</td>
							<td className={styles.table__item}>от 330 руб.</td>
						</tr>
						<tr className={styles.table__line}>
							<td className={styles.table__item}>До 22 мм</td>
							<td className={styles.table__item}>от 420 руб.</td>
						</tr>
						<tr className={styles.table__line}>
							<td className={styles.table__item}>До 25 мм</td>
							<td className={styles.table__item}>от 480 руб.</td>
						</tr>
						<tr className={styles.table__line}>
							<td className={styles.table__item}>До 30 мм</td>
							<td className={styles.table__item}>от 570 руб.</td>
						</tr>
						<tr className={styles.table__line}>
							<td className={styles.table__item}>До 32 мм</td>
							<td className={styles.table__item}>от 650 руб.</td>
						</tr>
						<tr className={styles.table__line}>
							<td className={styles.table__item}>До 38 мм</td>
							<td className={styles.table__item}>от 780 руб.</td>
						</tr>
						<tr className={styles.table__line}>
							<td className={styles.table__item}>До 40 мм</td>
							<td className={styles.table__item}>от 850 руб.</td>
						</tr>
						<tr className={styles.table__line}>
							<td className={styles.table__item}>До 50 мм</td>
							<td className={styles.table__item}>от 1 000 руб.</td>
						</tr>
						<tr className={styles.table__line}>
							<td className={styles.table__item}>До 58 мм</td>
							<td className={styles.table__item}>-</td>
						</tr>
						<tr className={styles.table__line}>
							<td className={styles.table__item}>До 60 мм</td>
							<td className={styles.table__item}>-</td>
						</tr>
					</tbody>
				</table>
			}
			{name === 'aluminii' &&
				<table className={styles.table}>
					<tbody>
						<tr className={styles.table__line}>
							<td className={styles.table__item}>До 1 мм</td>
							<td className={styles.table__item}>от 60 руб.</td>
						</tr>
						<tr className={styles.table__line}>
							<td className={styles.table__item}>До 2 мм</td>
							<td className={styles.table__item}>от 70 руб.</td>
						</tr>
						<tr className={styles.table__line}>
							<td className={styles.table__item}>До 3 мм</td>
							<td className={styles.table__item}>от 75 руб.</td>
						</tr>
						<tr className={styles.table__line}>
							<td className={styles.table__item}>До 4 мм</td>
							<td className={styles.table__item}>от 80 руб.</td>
						</tr>
						<tr className={styles.table__line}>
							<td className={styles.table__item}>До 5 мм</td>
							<td className={styles.table__item}>от 95 руб.</td>
						</tr>
						<tr className={styles.table__line}>
							<td className={styles.table__item}>До 6 мм</td>
							<td className={styles.table__item}>от 100 руб.</td>
						</tr>
						<tr className={styles.table__line}>
							<td className={styles.table__item}>До 8 мм</td>
							<td className={styles.table__item}>от 110 руб.</td>
						</tr>
						<tr className={styles.table__line}>
							<td className={styles.table__item}>До 10 мм</td>
							<td className={styles.table__item}>от 130 руб.</td>
						</tr>
						<tr className={styles.table__line}>
							<td className={styles.table__item}>До 12 мм</td>
							<td className={styles.table__item}>от 160 руб.</td>
						</tr>
						<tr className={styles.table__line}>
							<td className={styles.table__item}>До 14 мм</td>
							<td className={styles.table__item}>от 230 руб.</td>
						</tr>
						<tr className={styles.table__line}>
							<td className={styles.table__item}>До 16 мм</td>
							<td className={styles.table__item}>от 240 руб.</td>
						</tr>
						<tr className={styles.table__line}>
							<td className={styles.table__item}>До 18 мм</td>
							<td className={styles.table__item}>от 250 руб.</td>
						</tr>
						<tr className={styles.table__line}>
							<td className={styles.table__item}>До 20 мм</td>
							<td className={styles.table__item}>от 330 руб.</td>
						</tr>
						<tr className={styles.table__line}>
							<td className={styles.table__item}>До 22 мм</td>
							<td className={styles.table__item}>от 420 руб.</td>
						</tr>
						<tr className={styles.table__line}>
							<td className={styles.table__item}>До 25 мм</td>
							<td className={styles.table__item}>от 480 руб.</td>
						</tr>
						<tr className={styles.table__line}>
							<td className={styles.table__item}>До 30 мм</td>
							<td className={styles.table__item}>от 570 руб.</td>
						</tr>
						<tr className={styles.table__line}>
							<td className={styles.table__item}>До 32 мм</td>
							<td className={styles.table__item}>от 650 руб.</td>
						</tr>
						<tr className={styles.table__line}>
							<td className={styles.table__item}>До 38 мм</td>
							<td className={styles.table__item}>от 780 руб.</td>
						</tr>
						<tr className={styles.table__line}>
							<td className={styles.table__item}>До 40 мм</td>
							<td className={styles.table__item}>от 850 руб.</td>
						</tr>
						<tr className={styles.table__line}>
							<td className={styles.table__item}>До 50 мм</td>
							<td className={styles.table__item}>от 1 000 руб.</td>
						</tr>
						<tr className={styles.table__line}>
							<td className={styles.table__item}>До 58 мм</td>
							<td className={styles.table__item}>-</td>
						</tr>
						<tr className={styles.table__line}>
							<td className={styles.table__item}>До 60 мм</td>
							<td className={styles.table__item}>-</td>
						</tr>
					</tbody>
				</table>
			}
			{name === 'latun' &&
				<table className={styles.table}>
					<tbody>
						<tr className={styles.table__line}>
							<td className={styles.table__item}>До 1 мм</td>
							<td className={styles.table__item}>цена договорная</td>
						</tr>
						<tr className={styles.table__line}>
							<td className={styles.table__item}>До 2 мм</td>
							<td className={styles.table__item}>цена договорная</td>
						</tr>
						<tr className={styles.table__line}>
							<td className={styles.table__item}>До 3 мм</td>
							<td className={styles.table__item}>цена договорная</td>
						</tr>
						<tr className={styles.table__line}>
							<td className={styles.table__item}>До 4 мм</td>
							<td className={styles.table__item}>цена договорная</td>
						</tr>
						<tr className={styles.table__line}>
							<td className={styles.table__item}>До 5 мм</td>
							<td className={styles.table__item}>цена договорная</td>
						</tr>
						<tr className={styles.table__line}>
							<td className={styles.table__item}>До 6 мм</td>
							<td className={styles.table__item}>цена договорная</td>
						</tr>
						<tr className={styles.table__line}>
							<td className={styles.table__item}>До 8 мм</td>
							<td className={styles.table__item}>цена договорная</td>
						</tr>
						<tr className={styles.table__line}>
							<td className={styles.table__item}>До 10 мм</td>
							<td className={styles.table__item}>цена договорная</td>
						</tr>
						<tr className={styles.table__line}>
							<td className={styles.table__item}>До 12 мм</td>
							<td className={styles.table__item}>цена договорная</td>
						</tr>
						<tr className={styles.table__line}>
							<td className={styles.table__item}>До 14 мм</td>
							<td className={styles.table__item}>цена договорная</td>
						</tr>
						<tr className={styles.table__line}>
							<td className={styles.table__item}>До 16 мм</td>
							<td className={styles.table__item}>цена договорная</td>
						</tr>
						<tr className={styles.table__line}>
							<td className={styles.table__item}>До 18 мм</td>
							<td className={styles.table__item}>цена договорная</td>
						</tr>
						<tr className={styles.table__line}>
							<td className={styles.table__item}>До 20 мм</td>
							<td className={styles.table__item}>цена договорная</td>
						</tr>
						<tr className={styles.table__line}>
							<td className={styles.table__item}>До 22 мм</td>
							<td className={styles.table__item}>цена договорная</td>
						</tr>
						<tr className={styles.table__line}>
							<td className={styles.table__item}>До 25 мм</td>
							<td className={styles.table__item}>цена договорная</td>
						</tr>
						<tr className={styles.table__line}>
							<td className={styles.table__item}>До 30 мм</td>
							<td className={styles.table__item}>цена договорная</td>
						</tr>
						<tr className={styles.table__line}>
							<td className={styles.table__item}>До 32 мм</td>
							<td className={styles.table__item}>цена договорная</td>
						</tr>
						<tr className={styles.table__line}>
							<td className={styles.table__item}>До 38 мм</td>
							<td className={styles.table__item}>цена договорная</td>
						</tr>
						<tr className={styles.table__line}>
							<td className={styles.table__item}>До 40 мм</td>
							<td className={styles.table__item}>цена договорная</td>
						</tr>
						<tr className={styles.table__line}>
							<td className={styles.table__item}>До 50 мм</td>
							<td className={styles.table__item}>цена договорная</td>
						</tr>
						<tr className={styles.table__line}>
							<td className={styles.table__item}>До 58 мм</td>
							<td className={styles.table__item}>цена договорная</td>
						</tr>
						<tr className={styles.table__line}>
							<td className={styles.table__item}>До 60 мм</td>
							<td className={styles.table__item}>цена договорная</td>
						</tr>
					</tbody>
				</table>
			}
			{name === 'med' &&
				<table className={styles.table}>
					<tbody>
						<tr className={styles.table__line}>
							<td className={styles.table__item}>До 1 мм</td>
							<td className={styles.table__item}>цена договорная</td>
						</tr>
						<tr className={styles.table__line}>
							<td className={styles.table__item}>До 2 мм</td>
							<td className={styles.table__item}>цена договорная</td>
						</tr>
						<tr className={styles.table__line}>
							<td className={styles.table__item}>До 3 мм</td>
							<td className={styles.table__item}>цена договорная</td>
						</tr>
						<tr className={styles.table__line}>
							<td className={styles.table__item}>До 4 мм</td>
							<td className={styles.table__item}>цена договорная</td>
						</tr>
						<tr className={styles.table__line}>
							<td className={styles.table__item}>До 5 мм</td>
							<td className={styles.table__item}>цена договорная</td>
						</tr>
						<tr className={styles.table__line}>
							<td className={styles.table__item}>До 6 мм</td>
							<td className={styles.table__item}>цена договорная</td>
						</tr>
						<tr className={styles.table__line}>
							<td className={styles.table__item}>До 8 мм</td>
							<td className={styles.table__item}>цена договорная</td>
						</tr>
						<tr className={styles.table__line}>
							<td className={styles.table__item}>До 10 мм</td>
							<td className={styles.table__item}>цена договорная</td>
						</tr>
						<tr className={styles.table__line}>
							<td className={styles.table__item}>До 12 мм</td>
							<td className={styles.table__item}>цена договорная</td>
						</tr>
						<tr className={styles.table__line}>
							<td className={styles.table__item}>До 14 мм</td>
							<td className={styles.table__item}>цена договорная</td>
						</tr>
						<tr className={styles.table__line}>
							<td className={styles.table__item}>До 16 мм</td>
							<td className={styles.table__item}>цена договорная</td>
						</tr>
						<tr className={styles.table__line}>
							<td className={styles.table__item}>До 18 мм</td>
							<td className={styles.table__item}>цена договорная</td>
						</tr>
						<tr className={styles.table__line}>
							<td className={styles.table__item}>До 20 мм</td>
							<td className={styles.table__item}>цена договорная</td>
						</tr>
						<tr className={styles.table__line}>
							<td className={styles.table__item}>До 22 мм</td>
							<td className={styles.table__item}>цена договорная</td>
						</tr>
						<tr className={styles.table__line}>
							<td className={styles.table__item}>До 25 мм</td>
							<td className={styles.table__item}>цена договорная</td>
						</tr>
						<tr className={styles.table__line}>
							<td className={styles.table__item}>До 30 мм</td>
							<td className={styles.table__item}>цена договорная</td>
						</tr>
						<tr className={styles.table__line}>
							<td className={styles.table__item}>До 32 мм</td>
							<td className={styles.table__item}>цена договорная</td>
						</tr>
						<tr className={styles.table__line}>
							<td className={styles.table__item}>До 38 мм</td>
							<td className={styles.table__item}>цена договорная</td>
						</tr>
						<tr className={styles.table__line}>
							<td className={styles.table__item}>До 40 мм</td>
							<td className={styles.table__item}>цена договорная</td>
						</tr>
						<tr className={styles.table__line}>
							<td className={styles.table__item}>До 50 мм</td>
							<td className={styles.table__item}>цена договорная</td>
						</tr>
						<tr className={styles.table__line}>
							<td className={styles.table__item}>До 58 мм</td>
							<td className={styles.table__item}>цена договорная</td>
						</tr>
						<tr className={styles.table__line}>
							<td className={styles.table__item}>До 60 мм</td>
							<td className={styles.table__item}>цена договорная</td>
						</tr>
					</tbody>
				</table>
			}
		</section>
	);
}

TabPlazma.propTypes = {
	tab: PropTypes.shape({
		name: PropTypes.string.isRequired,
	}),
};

export default TabPlazma;
