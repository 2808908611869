import React from 'react';
import { LuClipboardList, LuCalculator, LuLayers, LuTruck, LuChevronRight } from "react-icons/lu";
import styles from "./steps.module.scss";

function Steps() {
	return (
		<div className={styles.wrapper}>
			<h2 className={styles.title}>Этапы работ</h2>
			<div className={styles.list}>
				<div className={styles.item}>
					<LuClipboardList className={styles.icon} />
					<h3 className={styles.item__title}>Заявка</h3>
				</div>
				<LuChevronRight className={styles.icon__arrow} />
				<div className={styles.item}>
					<LuCalculator className={styles.icon} />
					<h3 className={styles.item__title}>Расчет стоимости</h3>
				</div>
				<LuChevronRight className={styles.icon__arrow} />
				<div className={styles.item}>
					<LuLayers className={styles.icon} />
					<h3 className={styles.item__title}>Выполнение работ</h3>
				</div>
				<LuChevronRight className={styles.icon__arrow} />
				<div className={styles.item}>
					<LuTruck className={styles.icon} />
					<h3 className={styles.item__title}>Доставка или самовывоз</h3>
				</div>
			</div>
		</div>
	);
}

export default Steps;
