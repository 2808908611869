import React from 'react';
import cn from 'classnames';
import { IoLogoWhatsapp } from "react-icons/io";
import { FaTelegram } from "react-icons/fa";
import styles from "./contacts.module.scss";

function Contacts() {
	return (
		<div className={styles.wrapper} name="contact">
			<div className={styles.map}>
				<div dangerouslySetInnerHTML={{
					__html: '<iframe src="https://yandex.ru/map-widget/v1/?z=12&ol=biz&oid=64490533074" width="100%" height="500" frameborder="0" ></iframe> ',
				}}
				>
				</div>
			</div>
			<div className={styles.contacts}>
				<h2 className={styles.title}>Контакты</h2>
				<p className={styles.address}>Адрес: Московская обл., Балашиха, ул. Автозаводская, 48в</p>
				<a className={styles.phone} href='tel:+79255482308'>+7 (925) 548-23-08</a>
				<a className={styles.mail} href='mailto:info@metallrezka.ru'>info@metallrezka.ru</a>
				<div className={styles.socials}>
					<a href='https://wa.me/+79255482308' className={styles.social}><IoLogoWhatsapp className={styles.social__icon} /></a>
					<a href='https://t.me/+79255482308' className={styles.social}><FaTelegram className={cn(styles.social__icon, styles.social__icon__min)} /></a>
				</div>
			</div>
		</div>
	);
}

export default Contacts;




