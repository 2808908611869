import React, { useEffect, useState } from 'react';
import * as Scroll from 'react-scroll';
import styles from './scroll-button.module.scss';

function ScrollButton() {

	const scrollToTop = Scroll.animateScroll;

	const [scroll, setScroll] = useState(0);

	const handleScroll = () => {
		setScroll(window.scrollY);
	};

	useEffect(() => {
		window.addEventListener('scroll', handleScroll);
		return () => window.removeEventListener('scroll', handleScroll);
	}, []);

	const handleScrollToTop = () => {
		scrollToTop.scrollToTop();
	};
	return (
		<div onClick={handleScrollToTop}>
			{scroll > document.documentElement.clientHeight && <span className={styles.scroll}></span>}
		</div>
	);
}


export default ScrollButton;
