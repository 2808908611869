import React from 'react';
import cn from 'classnames';
import { NavLink } from 'react-router-dom';
import { Link } from 'react-scroll';
import { useMediaQuery } from 'react-responsive'
import { IoIosPin, IoLogoWhatsapp } from "react-icons/io";
import { FaTelegram } from "react-icons/fa";
import logo from '../../images/logo1-1.png';
import { AppRoute } from '../../const';
import styles from './header.module.scss';

import Dropdown from 'rc-dropdown';
import 'rc-dropdown/assets/index.css';

function Header() {

	const isMobileScreen = useMediaQuery({ query: '(max-width: 768px)' });

	const menuItems = [
		<NavLink className={styles.dropdown_link} to={AppRoute.PLAZMA} key='plazma'>Плазменная резка</NavLink>,
		<NavLink className={styles.dropdown_link} to={AppRoute.LAZER} key='lazer'>Лазерная резка</NavLink>,
	];

	const menu = (
		<div className={styles.dropdown_wrapper}>
			{menuItems}
		</div>
	);

	return (
		<div className={styles.header}>
			<div className={styles.header__top}>
				<div className={cn(styles.wrapper, styles.wrapper__top)}>
					<div className={styles.logo__wrapper}>
						<div className={styles.pulse__wrapper__mobile}>
							<div className={styles.pulse}></div>
							<p className={styles.pulse__text}>Сейчас работаем</p>
						</div>
						<NavLink to={AppRoute.MAIN} className={styles.logo_link}><img className={styles.logo} src={logo} width={850} height={850} alt={'Плазменная резка металла'} />
							<p className={styles.logo__text}>MetallRezka.ru</p></NavLink>
					</div>
					<div className={styles.text__wrapper}>
						<IoIosPin className={styles.pin} />
						<p className={styles.text}>Московская обл., Балашиха, ул.&nbsp;Автозаводская, 48в</p>
					</div>
					<div className={styles.email}>
						<a className={styles.mail} href='mailto:info@metallrezka.ru'>info@metallrezka.ru</a>
					</div>
					<div className={styles.phones}>
						<div className={styles.socials}>
							<a href='https://wa.me/+79255482308' className={styles.social}><IoLogoWhatsapp className={styles.social__icon} /></a>
							<a href='https://t.me/+79255482308' className={styles.social}><FaTelegram className={cn(styles.social__icon, styles.social__icon__min)} /></a>
						</div>
						{/* <a className={styles.phone} href='tel:+79255482308'>+7 (925) 548-23-08</a> */}
						<a className={styles.phone} href='tel:+79255482308'>+7 (925) 548-23-08</a>
					</div>
				</div>
			</div>
			<div className={styles.header__bottom}>
				<div className={cn(styles.wrapper, styles.wrapper__bottom)}>
					<div className={styles.pulse__wrapper}>
						<div className={styles.pulse}></div>
						<p className={styles.pulse__text}>Сейчас работаем</p>
					</div>
					<div className={styles.link__wrapper}>
						<Dropdown overlay={menu}>
							<div className={styles.link}>
								Услуги
							</div>
						</Dropdown>
						<Link className={styles.link} to="price" offset={-50} spy={true} smooth={true} duration={500}>Цены</Link>
						<Link className={styles.link} to="contact" offset={-100} spy={true} smooth={true} duration={500}>Контакты</Link>
					</div>
					<div className={styles.header__button_wrapper}>
						<Link className={styles.header__button} to="offer" offset={-150} spy={true} smooth={true} duration={500}><span className={styles.button__text}>{isMobileScreen ? 'Заявка' : 'Оставить заявку'}</span></Link>
					</div>
				</div>
			</div>
		</div>
	);
}

export default Header;
