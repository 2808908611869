import React from 'react';
import PropTypes from 'prop-types';
import photo1 from '../../images/photo_1.jpg';
import photo2 from '../../images/photo_2.jpg';
import photo3 from '../../images/photo_3.jpg';
import photo4 from '../../images/photo_4.jpg';
import photo5 from '../../images/photo_5.jpg';
import photo6 from '../../images/photo_6.jpg';
import photo7 from '../../images/photo_7.jpg';
import photo8 from '../../images/photo_8.jpg';
import photo9 from '../../images/photo_9.jpg';
import photo10 from '../../images/photo_10.jpg';
import photo11 from '../../images/photo_11.jpg';
import photo12 from '../../images/photo_12.jpg';
import styles from "./gallery.module.scss";

function Gallery({ usluga }) {
	return (
		<div className={styles.wrapper}>
			<h2 className={styles.title}>Наши работы</h2>
			{(usluga == 'plazma' || usluga == 'all') &&
				<div>
					<h3 className={styles.subtitle}>Плазменная резка</h3>
					<div className={styles.gallery}>
						<img className={styles.image} src={photo1} width={1125} height={633} alt={'Процесс резки металла в форме круга'} />
						<img className={styles.image} src={photo2} width={1125} height={633} alt={'Процесс резки металла в форме круга'} />
						<img className={styles.image} src={photo3} width={1125} height={633} alt={'Процесс резки металла в форме круга'} />
						<img className={styles.image} src={photo4} width={1125} height={633} alt={'Процесс резки металла в форме круга'} />
						<img className={styles.image} src={photo5} width={1125} height={633} alt={'Плазморез Cebora - Elettro PLASMA 1880 SYNERGIC LCD'} />
						<img className={styles.image} src={photo6} width={1125} height={633} alt={'Плазморез Cebora - Elettro PLASMA 1880 SYNERGIC LCD'} />
					</div>
				</div>
			}
			{(usluga == 'lazer' || usluga == 'all') &&
				<div>
					<h3 className={styles.subtitle}>Лазерная резка</h3>
					<div className={styles.gallery}>
						<img className={styles.image} src={photo7} width={1280} height={960} alt={'Процесс резки металла в форме круга'} />
						<img className={styles.image} src={photo8} width={1280} height={960} alt={'Процесс резки металла в форме круга'} />
						<img className={styles.image} src={photo9} width={1280} height={960} alt={'Процесс резки металла в форме круга'} />
						<img className={styles.image} src={photo10} width={1125} height={633} alt={'Процесс резки металла в форме круга'} />
						<img className={styles.image} src={photo11} width={1125} height={633} alt={'Оптоволоконный лазерный станок BODOR'} />
						<img className={styles.image} src={photo12} width={1125} height={633} alt={'Процесс резки'} />
					</div>
				</div>
			}
		</div>
	);
}

Gallery.propTypes = {
	usluga: PropTypes.string.isRequired,
};

export default Gallery;
