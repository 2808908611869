import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useMediaQuery } from 'react-responsive';
import cn from 'classnames';
import TabPlazma from '../tab-plazma/tab-plazma';
import TabLazer from '../tab-lazer/tab-lazer';
import { tabsPlazma, tabsLazer } from '../../const';
import styles from './price.module.scss';

function Price({ usluga }) {

  const isDesktopOrTablet = useMediaQuery({
    query: '(min-width: 769px)',
  });
  const isMobile = useMediaQuery({
    query: '(max-width: 768px)',
  });

  const [activeTabPlazma, setActiveTabPlazma] = useState(tabsPlazma[0]);
  const [activeTabLazer, setActiveTabLazer] = useState(tabsLazer[0]);

  const handleButtonClickPlazma = (tab) => {
    setActiveTabPlazma(tab);
  };

  const handleButtonClickLazer = (tab) => {
    setActiveTabLazer(tab);
  };

  return (
    <div className={styles.wrapper} name='price'>
      <h2 className={styles.title}>Наши цены</h2>
      {(usluga == 'plazma' || usluga == 'all') &&
        <div>
          <h3 className={styles.subtitle}>Плазменная резка</h3>
          {isDesktopOrTablet &&
            <table className={styles.table}>
              <tbody>
                <tr className={styles.table__line}>
                  <th className={styles.table__title}>S mm</th>
                  <th className={styles.table__title}>Сталь черная</th>
                  <th className={styles.table__title}>Нержавающая сталь</th>
                  <th className={styles.table__title}>Алюминий</th>
                  <th className={styles.table__title}>Латунь</th>
                  <th className={styles.table__title}>Медь</th>
                </tr>
                <tr className={styles.table__line}>
                  <td className={styles.table__item}>До 1 мм</td>
                  <td className={styles.table__item}>от 20 руб.</td>
                  <td className={styles.table__item}>от 60 руб.</td>
                  <td className={styles.table__item}>от 60 руб.</td>
                  <td className={styles.table__item}>цена договорная</td>
                  <td className={styles.table__item}>цена договорная</td>
                </tr>
                <tr className={styles.table__line}>
                  <td className={styles.table__item}>До 2 мм</td>
                  <td className={styles.table__item}>от 25 руб.</td>
                  <td className={styles.table__item}>от 70 руб.</td>
                  <td className={styles.table__item}>от 70 руб.</td>
                  <td className={styles.table__item}>цена договорная</td>
                  <td className={styles.table__item}>цена договорная</td>
                </tr>
                <tr className={styles.table__line}>
                  <td className={styles.table__item}>До 3 мм</td>
                  <td className={styles.table__item}>от 30 руб.</td>
                  <td className={styles.table__item}>от 75 руб.</td>
                  <td className={styles.table__item}>от 75 руб.</td>
                  <td className={styles.table__item}>цена договорная</td>
                  <td className={styles.table__item}>цена договорная</td>
                </tr>
                <tr className={styles.table__line}>
                  <td className={styles.table__item}>До 4 мм</td>
                  <td className={styles.table__item}>от 35 руб.</td>
                  <td className={styles.table__item}>от 80 руб.</td>
                  <td className={styles.table__item}>от 80 руб.</td>
                  <td className={styles.table__item}>цена договорная</td>
                  <td className={styles.table__item}>цена договорная</td>
                </tr>
                <tr className={styles.table__line}>
                  <td className={styles.table__item}>До 5 мм</td>
                  <td className={styles.table__item}>от 40 руб.</td>
                  <td className={styles.table__item}>от 95 руб.</td>
                  <td className={styles.table__item}>от 95 руб.</td>
                  <td className={styles.table__item}>цена договорная</td>
                  <td className={styles.table__item}>цена договорная</td>
                </tr>
                <tr className={styles.table__line}>
                  <td className={styles.table__item}>До 6 мм</td>
                  <td className={styles.table__item}>от 45 руб.</td>
                  <td className={styles.table__item}>от 100 руб.</td>
                  <td className={styles.table__item}>от 100 руб.</td>
                  <td className={styles.table__item}>цена договорная</td>
                  <td className={styles.table__item}>цена договорная</td>
                </tr>
                <tr className={styles.table__line}>
                  <td className={styles.table__item}>До 8 мм</td>
                  <td className={styles.table__item}>от 50 руб.</td>
                  <td className={styles.table__item}>от 110 руб.</td>
                  <td className={styles.table__item}>от 110 руб.</td>
                  <td className={styles.table__item}>цена договорная</td>
                  <td className={styles.table__item}>цена договорная</td>
                </tr>
                <tr className={styles.table__line}>
                  <td className={styles.table__item}>До 10 мм</td>
                  <td className={styles.table__item}>от 60 руб.</td>
                  <td className={styles.table__item}>от 130 руб.</td>
                  <td className={styles.table__item}>от 130 руб.</td>
                  <td className={styles.table__item}>цена договорная</td>
                  <td className={styles.table__item}>цена договорная</td>
                </tr>
                <tr className={styles.table__line}>
                  <td className={styles.table__item}>До 12 мм</td>
                  <td className={styles.table__item}>от 70 руб.</td>
                  <td className={styles.table__item}>от 160 руб.</td>
                  <td className={styles.table__item}>от 160 руб.</td>
                  <td className={styles.table__item}>цена договорная</td>
                  <td className={styles.table__item}>цена договорная</td>
                </tr>
                <tr className={styles.table__line}>
                  <td className={styles.table__item}>До 14 мм</td>
                  <td className={styles.table__item}>от 80 руб.</td>
                  <td className={styles.table__item}>от 230 руб.</td>
                  <td className={styles.table__item}>от 230 руб.</td>
                  <td className={styles.table__item}>цена договорная</td>
                  <td className={styles.table__item}>цена договорная</td>
                </tr>
                <tr className={styles.table__line}>
                  <td className={styles.table__item}>До 16 мм</td>
                  <td className={styles.table__item}>от 90 руб.</td>
                  <td className={styles.table__item}>от 240 руб.</td>
                  <td className={styles.table__item}>от 240 руб.</td>
                  <td className={styles.table__item}>цена договорная</td>
                  <td className={styles.table__item}>цена договорная</td>
                </tr>
                <tr className={styles.table__line}>
                  <td className={styles.table__item}>До 18 мм</td>
                  <td className={styles.table__item}>от 100 руб.</td>
                  <td className={styles.table__item}>от 250 руб.</td>
                  <td className={styles.table__item}>от 250 руб.</td>
                  <td className={styles.table__item}>цена договорная</td>
                  <td className={styles.table__item}>цена договорная</td>
                </tr>
                <tr className={styles.table__line}>
                  <td className={styles.table__item}>До 20 мм</td>
                  <td className={styles.table__item}>от 110 руб.</td>
                  <td className={styles.table__item}>от 330 руб.</td>
                  <td className={styles.table__item}>от 330 руб.</td>
                  <td className={styles.table__item}>цена договорная</td>
                  <td className={styles.table__item}>цена договорная</td>
                </tr>
                <tr className={styles.table__line}>
                  <td className={styles.table__item}>До 22 мм</td>
                  <td className={styles.table__item}>от 120 руб.</td>
                  <td className={styles.table__item}>от 420 руб.</td>
                  <td className={styles.table__item}>от 420 руб.</td>
                  <td className={styles.table__item}>цена договорная</td>
                  <td className={styles.table__item}>цена договорная</td>
                </tr>
                <tr className={styles.table__line}>
                  <td className={styles.table__item}>До 25 мм</td>
                  <td className={styles.table__item}>от 200 руб.</td>
                  <td className={styles.table__item}>от 480 руб.</td>
                  <td className={styles.table__item}>от 480 руб.</td>
                  <td className={styles.table__item}>цена договорная</td>
                  <td className={styles.table__item}>цена договорная</td>
                </tr>
                <tr className={styles.table__line}>
                  <td className={styles.table__item}>До 30 мм</td>
                  <td className={styles.table__item}>от 250 руб.</td>
                  <td className={styles.table__item}>от 570 руб.</td>
                  <td className={styles.table__item}>от 570 руб.</td>
                  <td className={styles.table__item}>цена договорная</td>
                  <td className={styles.table__item}>цена договорная</td>
                </tr>
                <tr className={styles.table__line}>
                  <td className={styles.table__item}>До 32 мм</td>
                  <td className={styles.table__item}>от 300 руб.</td>
                  <td className={styles.table__item}>от 650 руб.</td>
                  <td className={styles.table__item}>от 650 руб.</td>
                  <td className={styles.table__item}>цена договорная</td>
                  <td className={styles.table__item}>цена договорная</td>
                </tr>
                <tr className={styles.table__line}>
                  <td className={styles.table__item}>До 38 мм</td>
                  <td className={styles.table__item}>от 400 руб.</td>
                  <td className={styles.table__item}>от 780 руб.</td>
                  <td className={styles.table__item}>от 780 руб.</td>
                  <td className={styles.table__item}>цена договорная</td>
                  <td className={styles.table__item}>цена договорная</td>
                </tr>
                <tr className={styles.table__line}>
                  <td className={styles.table__item}>До 40 мм</td>
                  <td className={styles.table__item}>от 450 руб.</td>
                  <td className={styles.table__item}>от 850 руб.</td>
                  <td className={styles.table__item}>от 850 руб.</td>
                  <td className={styles.table__item}>цена договорная</td>
                  <td className={styles.table__item}>цена договорная</td>
                </tr>
                <tr className={styles.table__line}>
                  <td className={styles.table__item}>До 50 мм</td>
                  <td className={styles.table__item}>от 550 руб.</td>
                  <td className={styles.table__item}>от 1 000 руб.</td>
                  <td className={styles.table__item}>от 1 000 руб.</td>
                  <td className={styles.table__item}>цена договорная</td>
                  <td className={styles.table__item}>цена договорная</td>
                </tr>
                <tr className={styles.table__line}>
                  <td className={styles.table__item}>До 58 мм</td>
                  <td className={styles.table__item}>от 650 руб.</td>
                  <td className={styles.table__item}>-</td>
                  <td className={styles.table__item}>-</td>
                  <td className={styles.table__item}>цена договорная</td>
                  <td className={styles.table__item}>цена договорная</td>
                </tr>
                <tr className={styles.table__line}>
                  <td className={styles.table__item}>До 60 мм</td>
                  <td className={styles.table__item}>от 700 руб.</td>
                  <td className={styles.table__item}>-</td>
                  <td className={styles.table__item}>-</td>
                  <td className={styles.table__item}>цена договорная</td>
                  <td className={styles.table__item}>цена договорная</td>
                </tr>
              </tbody>
            </table>}
          {isMobile &&
            <div className={styles.container__mobile}>
              <div className={styles.tabs}>
                {
                  tabsPlazma.map((tab) => (
                    <button
                      className={cn(styles.table__title, styles.table__title__mobile, activeTabPlazma === tab && styles.table__title__mobile__active)}
                      key={tab.id}
                      type="button"
                      onClick={() => {
                        handleButtonClickPlazma(tab);
                      }}
                      aria-label={tab.tabName}
                    >
                      <span className={cn(`${tab.name}`, styles.icon)}></span>
                      {tab.tabName}
                    </button>
                  ))
                }
              </div>
              <TabPlazma tab={activeTabPlazma} />
            </div>
          }
        </div>
      }
      {(usluga == 'lazer' || usluga == 'all') &&
        <div>
          <h3 className={styles.subtitle}>Лазерная резка</h3>
          {isDesktopOrTablet &&
            <table className={styles.table}>
              <tbody>
                <tr className={styles.table__line}>
                  <th className={styles.table__title}>S mm</th>
                  <th className={styles.table__title}>Алюминий дюраль (АМГ, АМЦ)</th>
                  <th className={styles.table__title}>Нержавающая сталь</th>
                  <th className={styles.table__title}>Сталь листовая углеродистая (Г/К и Х/К)</th>
                </tr>
                <tr className={styles.table__line}>
                  <td className={styles.table__item}>До 1 мм</td>
                  <td className={styles.table__item}>44 руб.</td>
                  <td className={styles.table__item}>46 руб.</td>
                  <td className={styles.table__item}>33 руб.</td>
                </tr>
                <tr className={styles.table__line}>
                  <td className={styles.table__item}>1,5 мм</td>
                  <td className={styles.table__item}>76 руб.</td>
                  <td className={styles.table__item}>76 руб.</td>
                  <td className={styles.table__item}>35 руб.</td>
                </tr>
                <tr className={styles.table__line}>
                  <td className={styles.table__item}>2 мм</td>
                  <td className={styles.table__item}>100 руб.</td>
                  <td className={styles.table__item}>100 руб.</td>
                  <td className={styles.table__item}>37 руб.</td>
                </tr>
                <tr className={styles.table__line}>
                  <td className={styles.table__item}>2,5 мм</td>
                  <td className={styles.table__item}>цена договорная</td>
                  <td className={styles.table__item}>цена договорная</td>
                  <td className={styles.table__item}>43 руб.</td>
                </tr>
                <tr className={styles.table__line}>
                  <td className={styles.table__item}>3 мм</td>
                  <td className={styles.table__item}>139 руб.</td>
                  <td className={styles.table__item}>140 руб.</td>
                  <td className={styles.table__item}>53 руб.</td>
                </tr>
                <tr className={styles.table__line}>
                  <td className={styles.table__item}>4 мм</td>
                  <td className={styles.table__item}>190 руб.</td>
                  <td className={styles.table__item}>188 руб.</td>
                  <td className={styles.table__item}>63 руб.</td>
                </tr>
                <tr className={styles.table__line}>
                  <td className={styles.table__item}>5 мм</td>
                  <td className={styles.table__item}>275 руб.</td>
                  <td className={styles.table__item}>271 руб.</td>
                  <td className={styles.table__item}>78 руб.</td>
                </tr>
                <tr className={styles.table__line}>
                  <td className={styles.table__item}>6 мм</td>
                  <td className={styles.table__item}>372 руб.</td>
                  <td className={styles.table__item}>366 руб.</td>
                  <td className={styles.table__item}>103 руб.</td>
                </tr>
                <tr className={styles.table__line}>
                  <td className={styles.table__item}>8 мм</td>
                  <td className={styles.table__item}>568 руб.</td>
                  <td className={styles.table__item}>558 руб.</td>
                  <td className={styles.table__item}>148 руб.</td>
                </tr>
                <tr className={styles.table__line}>
                  <td className={styles.table__item}>10 мм</td>
                  <td className={styles.table__item}>711 руб.</td>
                  <td className={styles.table__item}>698 руб.</td>
                  <td className={styles.table__item}>198 руб.</td>
                </tr>
                <tr className={styles.table__line}>
                  <td className={styles.table__item}>12 мм</td>
                  <td className={styles.table__item}>цена договорная</td>
                  <td className={styles.table__item}>цена договорная</td>
                  <td className={styles.table__item}>248 руб.</td>
                </tr>
                <tr className={styles.table__line}>
                  <td className={styles.table__item}>16 мм</td>
                  <td className={styles.table__item}>цена договорная</td>
                  <td className={styles.table__item}>цена договорная</td>
                  <td className={styles.table__item}>438 руб.</td>
                </tr>
                <tr className={styles.table__line}>
                  <td className={styles.table__item}>20 мм</td>
                  <td className={styles.table__item}>цена договорная</td>
                  <td className={styles.table__item}>цена договорная</td>
                  <td className={styles.table__item}>513 руб.</td>
                </tr>
              </tbody>
            </table>}
          {isMobile &&
            <div className={styles.container__mobile}>
              <div className={styles.tabs}>
                {
                  tabsLazer.map((tab) => (
                    <button
                      className={cn(styles.table__title, styles.table__title__mobile, activeTabLazer === tab && styles.table__title__mobile__active)}
                      key={tab.id}
                      type="button"
                      onClick={() => {
                        handleButtonClickLazer(tab);
                      }}
                      aria-label={tab.tabName}
                    >
                      <span className={cn(`${tab.name}`, styles.icon)}></span>
                      {tab.tabName}
                    </button>
                  ))
                }
              </div>
              <TabLazer tab={activeTabLazer} />
            </div>
          }
        </div>
      }


    </div>
  );
}

Price.propTypes = {
  usluga: PropTypes.string.isRequired,
};

export default Price;
