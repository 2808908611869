import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './components/app/app';
import './styles/index.scss';

window.addEventListener('orientationchange', () => {
  const orientation = window.matchMedia('(orientation: landscape)');

  if (orientation.matches) {
    window.location.reload();
  }
});

const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);



