import React from 'react';
import PropTypes from 'prop-types';
import styles from './tab-lazer.module.scss';

function TabLazer({ tab }) {

	const { name } = tab;

	return (
		<section className={styles.wrapper}>
			{name === 'aluminii' &&
				<table className={styles.table}>
					<tbody>
						<tr className={styles.table__line}>
							<td className={styles.table__item}>До 1 мм</td>
							<td className={styles.table__item}>44 руб.</td>
						</tr>
						<tr className={styles.table__line}>
							<td className={styles.table__item}>1,5 мм</td>
							<td className={styles.table__item}>76 руб.</td>
						</tr>
						<tr className={styles.table__line}>
							<td className={styles.table__item}>2 мм</td>
							<td className={styles.table__item}>100 руб.</td>
						</tr>
						<tr className={styles.table__line}>
							<td className={styles.table__item}>3 мм</td>
							<td className={styles.table__item}>139 руб.</td>
						</tr>
						<tr className={styles.table__line}>
							<td className={styles.table__item}>4 мм</td>
							<td className={styles.table__item}>190 руб.</td>
						</tr>
						<tr className={styles.table__line}>
							<td className={styles.table__item}>5 мм</td>
							<td className={styles.table__item}>275 руб.</td>
						</tr>
						<tr className={styles.table__line}>
							<td className={styles.table__item}>6 мм</td>
							<td className={styles.table__item}>372 руб.</td>
						</tr>
						<tr className={styles.table__line}>
							<td className={styles.table__item}>8 мм</td>
							<td className={styles.table__item}>568 руб.</td>
						</tr>
						<tr className={styles.table__line}>
							<td className={styles.table__item}>10 мм</td>
							<td className={styles.table__item}>711 руб.</td>
						</tr>
					</tbody>
				</table>
			}
			{name === 'nerzh' &&
				<table className={styles.table}>
					<tbody>
						<tr className={styles.table__line}>
							<td className={styles.table__item}>До 1 мм</td>
							<td className={styles.table__item}>46 руб.</td>
						</tr>
						<tr className={styles.table__line}>
							<td className={styles.table__item}>1,5 мм</td>
							<td className={styles.table__item}>76 руб.</td>
						</tr>
						<tr className={styles.table__line}>
							<td className={styles.table__item}>2 мм</td>
							<td className={styles.table__item}>100 руб.</td>
						</tr>
						<tr className={styles.table__line}>
							<td className={styles.table__item}>3 мм</td>
							<td className={styles.table__item}>140 руб.</td>
						</tr>
						<tr className={styles.table__line}>
							<td className={styles.table__item}>4 мм</td>
							<td className={styles.table__item}>188 руб.</td>
						</tr>
						<tr className={styles.table__line}>
							<td className={styles.table__item}>5 мм</td>
							<td className={styles.table__item}>271 руб.</td>
						</tr>
						<tr className={styles.table__line}>
							<td className={styles.table__item}>6 мм</td>
							<td className={styles.table__item}>366 руб.</td>
						</tr>
						<tr className={styles.table__line}>
							<td className={styles.table__item}>8 мм</td>
							<td className={styles.table__item}>558 руб.</td>
						</tr>
						<tr className={styles.table__line}>
							<td className={styles.table__item}>10 мм</td>
							<td className={styles.table__item}>698 руб.</td>
						</tr>
					</tbody>
				</table>
			}
			{name === 'stal' &&
				<table className={styles.table}>
					<tbody>
						<tr className={styles.table__line}>
							<td className={styles.table__item}>До 1 мм</td>
							<td className={styles.table__item}>33 руб.</td>
						</tr>
						<tr className={styles.table__line}>
							<td className={styles.table__item}>1,5 мм</td>
							<td className={styles.table__item}>35 руб.</td>
						</tr>
						<tr className={styles.table__line}>
							<td className={styles.table__item}>2 мм</td>
							<td className={styles.table__item}>37 руб.</td>
						</tr>
						<tr className={styles.table__line}>
							<td className={styles.table__item}>2,5 мм</td>
							<td className={styles.table__item}>43 руб.</td>
						</tr>
						<tr className={styles.table__line}>
							<td className={styles.table__item}>3 мм</td>
							<td className={styles.table__item}>53 руб.</td>
						</tr>
						<tr className={styles.table__line}>
							<td className={styles.table__item}>4 мм</td>
							<td className={styles.table__item}>63 руб.</td>
						</tr>
						<tr className={styles.table__line}>
							<td className={styles.table__item}>5 мм</td>
							<td className={styles.table__item}>78 руб.</td>
						</tr>
						<tr className={styles.table__line}>
							<td className={styles.table__item}>6 мм</td>
							<td className={styles.table__item}>103 руб.</td>
						</tr>
						<tr className={styles.table__line}>
							<td className={styles.table__item}>8 мм</td>
							<td className={styles.table__item}>148 руб.</td>
						</tr>
						<tr className={styles.table__line}>
							<td className={styles.table__item}>10 мм</td>
							<td className={styles.table__item}>198 руб.</td>
						</tr>
						<tr className={styles.table__line}>
							<td className={styles.table__item}>12 мм</td>
							<td className={styles.table__item}>248 руб.</td>
						</tr>
						<tr className={styles.table__line}>
							<td className={styles.table__item}>16 мм</td>
							<td className={styles.table__item}>438 руб.</td>
						</tr>
						<tr className={styles.table__line}>
							<td className={styles.table__item}>20 мм</td>
							<td className={styles.table__item}>513 руб.</td>
						</tr>
					</tbody>
				</table>
			}
		</section>
	);
}

TabLazer.propTypes = {
	tab: PropTypes.shape({
		name: PropTypes.string.isRequired,
	}),
};

export default TabLazer;
