import React from 'react';
import PropTypes from 'prop-types';
import { MdDoneOutline } from "react-icons/md";
import apparat from '../../images/apparat.jpeg';
import bodor from '../../images/banner-a.jpeg';
import styles from "./about.module.scss";

function About({ usluga }) {
	return (
		<div className={styles.wrapper}>
			<h2 className={styles.title}>Оборудование</h2>
			{(usluga == 'plazma' || usluga == 'all') &&
				<div className={styles.container}>
					<div className={styles.about}>
						<p><span className={styles.bold}>Плазморез Cebora - Elettro PLASMA 1880 SYNERGIC LCD (ЧПУ)</span> с мощным источником плазмы и размером рабочего поля для резки металла 2000 х 6000 мм! Железно режет до 80 мм!</p>
						<p>У нас самый мощный синергетический плазменный инвертор в мире!</p>
						<ul className={styles.list}>
							<li className={styles.item}><MdDoneOutline className={styles.icon} />Обеспечиваем реализацию схем экономичного раскроя!</li>
							<li className={styles.item}><MdDoneOutline className={styles.icon} />Выполняем сварку конструкций без всякой последующей механической обработки.</li>
							<li className={styles.item}><MdDoneOutline className={styles.icon} />При воздушно - плазменной резке получается хорошее качество кромок, отсутствуют наплывы и деформация.</li>
							<li className={styles.item}><MdDoneOutline className={styles.icon} />Отсутствие деформаций гарантируется и для листовых заготовок малой толщины.</li>
						</ul>
					</div>
					<div className={styles.image}>
						<img src={apparat} width={1063} height={883} alt={'Наше оборудование Плазморез Cebora'} />
					</div>
				</div>
			}
			{(usluga == 'lazer' || usluga == 'all') &&
				<div className={styles.container}>
					<div className={styles.image_left}>
						<img src={bodor} width={1200} height={686} alt={'Наше оборудование Оптоволоконный лазерный станок BODOR'} />
					</div>
					<div className={styles.about_left}>
						<p><span className={styles.bold}>Оптоволоконный лазерный станок BODOR.</span> Лазерный станок для резки листового металла мощностью 3 киловатта! Лазерный источник MAX PHOTONICS.</p>
						<ul className={styles.list}>
							<li className={styles.item}><MdDoneOutline className={styles.icon} />Мощный лазер и специальное программное обеспечение делают резку быстрой, экономичной и качественной.</li>
							<li className={styles.item}><MdDoneOutline className={styles.icon} />Лазерная головка способна обнаруживать и обходить препятствия.</li>
							<li className={styles.item}><MdDoneOutline className={styles.icon} />Газосберегающее сопло создает постоянное стабильное давление режущего газа.</li>
							<li className={styles.item}><MdDoneOutline className={styles.icon} />Точность и отсутствие следов вибрации даже при работе по краю тонкого листа.</li>
						</ul>
					</div>
				</div>
			}
		</div>
	);
}

About.propTypes = {
	usluga: PropTypes.string.isRequired,
};

export default About;
