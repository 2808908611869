import React from 'react';
import cn from 'classnames';
import stal from '../../images/stal-chern.jpg';
import nerzh from '../../images/nerzh.jpg';
import aluminii from '../../images/aluminii.jpg';
import latun from '../../images/latun.jpg';
import med from '../../images/med.jpg';
import styles from './rezka.module.scss';

function Rezka() {
	return (
		<div className={styles.wrapper}>
			<h2 className={styles.title}>Режем любые листовые металлы</h2>
			<ul className={styles.list}>
				<li className={styles.item}>
					<img className={styles.image} src={stal} width={400} height={300} alt={'Сталь черная'} />
					<h3 className={styles.item__title}>Сталь черная</h3>
					<p className={styles.item__text}>от 1 мм</p>
				</li>
				<li className={styles.item}>
					<img className={styles.image} src={nerzh} width={400} height={300} alt={'Сталь нержавеющая'} />
					<h3 className={styles.item__title}>Сталь нержавеющая</h3>
					<p className={styles.item__text}>от 1 мм</p>
				</li>
				<li className={styles.item}>
					<img className={styles.image} src={aluminii} width={400} height={300} alt={'Алюминий'} />
					<h3 className={styles.item__title}>Алюминий</h3>
					<p className={styles.item__text}>от 1 мм</p>
				</li>
			</ul>
			<ul className={cn(styles.list, styles.list__min)}>
				<li className={styles.item}>
					<img className={styles.image} src={latun} width={400} height={300} alt={'Латунь'} />
					<h3 className={styles.item__title}>Латунь</h3>
					<p className={styles.item__text}>от 1 мм</p>
				</li>
				<li className={styles.item}>
					<img className={styles.image} src={med} width={400} height={300} alt={'Медь'} />
					<h3 className={styles.item__title}>Медь</h3>
					<p className={styles.item__text}>от 1 мм</p>
				</li>
			</ul>
		</div>
	);
}

export default Rezka;
