import React from 'react';
import styles from "./footer.module.scss";

function Footer() {
	return (
		<div className={styles.footer}>
			<div className={styles.wrapper}>
				<p className={styles.text}>MetallRezka.ru © 2024 - Изготовление изделий из металла любой сложности</p>
				<a className={styles.informer} href="https://metrika.yandex.ru/stat/?id=96011415&amp;from=informer" target="_blank" rel="nofollow noreferrer"><img src="https://informer.yandex.ru/informer/96011415/3_0_202020FF_000000FF_1_pageviews" alt="Яндекс.Метрика" title="Яндекс.Метрика: данные за сегодня (просмотры, визиты и уникальные посетители)" data-cid="96011415" data-lang="ru" /></a>
			</div>
		</div>
	);
}

export default Footer;
