import React from 'react';
import styles from './plazma-page.module.scss';
import Price from '../price/price';
import Header from '../header/header';
import Footer from '../footer/footer';
import Rezka from '../rezka/rezka';
import Offer from '../offer/offer';
import MainTop from '../main-top/main-top';
import Steps from '../steps/steps';
import About from '../about/about';
import Contacts from '../contacts/contacts';
import Gallery from '../gallery/gallery';
import ScrollButton from '../scroll-button/scroll-button';
import { video } from '../../const';

function PlazmaPage() {
	return (
		<div className={styles.wrapper}>
			<Header />
			<main className={styles.main}>
				<MainTop video={video[1]} />
				<h1 className='visually-hidden' >Плазменная резка</h1>
				<About usluga='plazma' />
				<Price usluga='plazma' />
				<Rezka />
				<Steps />
				<Offer usluga='plazma' />
				<Gallery usluga='plazma' />
				<Contacts />
				<ScrollButton />
			</main>
			<Footer />
		</div>
	);
}

export default PlazmaPage;
